.productPageContainer {
    width: 100%;
    /* background-color: #1a1b1b; */
}

.Box {
    display: flex;
}

.leftBox {
    display: block ;
    width: 20%;
}

.leftBox>h2 {
    font: 600 1.5vmax 'Roboto';
    color: var(--textC1);
    text-transform: uppercase;
    padding-bottom: 1vmax;
    padding-left: 2vmax;
    /* text-align: center; */
}

.productList {
    width: 100%;
    height: 130vh;
    padding: 0 2vmax;
    /* border: 1px solid gray; */
    overflow: scroll;

}

.productList::-webkit-scrollbar {
    width: 0px;
    /* background-color: aquamarine; */

}

.productList::-webkit-scrollbar-thumb {
    width: 5px;
    background-color: var(--bg3);
    border-radius: 4rem;
}

.productList>h2 {}

.productList>a {
    display: block;
    width: 100%;
    text-decoration: none;
    font: 300 1vmax 'Roboto';
    /* background-color: var(--bg1); */
    color: var(--textC1);
    /* text-transform: uppercase; */
    padding: 0.4vmax;
    display: flex;
    align-self: center;
    justify-content: center;
    color: rgb(120, 120, 120) !important;
    margin-bottom: 4px
}

.rightBox {
    width: 80%;
}

#white {
    /* background-color: var(--textLight); */
}

#black {
    /* background-color: var(--textDark); */
}

.colorSwitch {
    /* margin-top: 5vmax; */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* background-color: var(--bg1); */
    /* padding: 4.9vmax 1vmax; */
}

.colorSwitch>span {
    width: 30px;
    height: 30px;
    display: inline-block;
    margin: 0.2vmax;
    border-radius: 50%;
    /* border: 2px solid gray; */
    cursor: pointer;
}

.productBox {
    width: 100%;
    padding: 2vmax 4vmax;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    gap: 2rem;
    /* background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.dev/svgjs' width='1440' height='860' preserveAspectRatio='none' viewBox='0 0 1440 860'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1016%26quot%3b)' fill='none'%3e%3cpath d='M 0%2c216 C 96%2c199.8 288%2c149.4 480%2c135 C 672%2c120.6 768%2c152.6 960%2c144 C 1152%2c135.4 1344%2c102.4 1440%2c92L1440 860L0 860z' fill='rgba(255%2c 255%2c 255%2c 0)'%3e%3c/path%3e%3cpath d='M 0%2c388 C 96%2c421.2 288%2c548.4 480%2c554 C 672%2c559.6 768%2c416.4 960%2c416 C 1152%2c415.6 1344%2c524.8 1440%2c552L1440 860L0 860z' fill='rgba(228%2c 248%2c 243%2c 0.39)'%3e%3c/path%3e%3cpath d='M 0%2c593 C 96%2c633 288%2c790 480%2c793 C 672%2c796 768%2c596 960%2c608 C 1152%2c620 1344%2c804 1440%2c853L1440 860L0 860z' fill='rgba(233%2c 233%2c 233%2c 0.22)'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1016'%3e%3crect width='1440' height='860' fill='white'%3e%3c/rect%3e%3c/mask%3e%3c/defs%3e%3c/svg%3e"); */
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
}

.imgbox {
    width: 50%;
    /* height: auto; */
    /* border: 1px solid var(--textC3); */
    padding: 0.5vmax;
}

.carousel-status {
    display: none;
}

.thumbs {
    display: flex;
    justify-content: center;
}

.thumb {
    width: 45px !important;
    /* height: 40px !important; */
    border: 2px solid var(--bg1) !important;
    /* padding: 1px !important; */
    border-radius: 0.1rem;
}

.thumb>img {
    width: 100% !important;
    border-radius: 0.2rem;

}


.productDetailBox {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    padding-top: 1vmax;
}

.productDetailBox>span {
    background-color: var(--bg4);
    padding: 0.8vmax;
    border-radius: 0.1rem;
}

.lineSty {
    width: 18%;
    height: 2px;
    background-color: var(--textC1);
}

.h2Sty {
    font: 600 2vmax 'Roboto';
    color: var(--textC1);

}

.h3Sty {
    font: 600 1vmax 'Roboto';
    color: var(--bg1);
}

.h4Sty {
    font: 600 1.5vmax 'Roboto';
    color: var(--bg1);
}

.bg1 {
    color: var(--bg1) !important;
    
}

.productDetailBox>p>pre,
.productDetailBox>p {
    font: 300 1.08vmax 'Roboto';
    line-height: 23px;
    color: var(--textDark);
}

.productDetailBox>p>span {

    color: var(--bg1);
    font-weight: 600;
}


.discriptionBox {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    /* border-top: 1px solid var(--textC3);      */
    padding-top: 1rem;
}

.actionBox {
    display: flex;
    gap: 1rem;
    padding: 1vmax 0;
    border-top: 1px solid var(--textC3);
}

.buybtn {
    padding: 0.7vmax 3vmax;
    font: 400 1vmax 'Roboto';
    color: var(--textC2);
    background-color: var(--textC1);
    border: none;
    cursor: pointer;
    transition: all 0.5s;
    border-radius: 2rem;
}

.buybtn:hover {
    box-shadow: 10px 10px 1px var(--bg3)
}


.products-row-scroll {
    width: 100%;
    overflow-x: auto;
    display: flex;
    gap: 1rem;
    padding: 2vmax 5vmax;
}

.products-row-scroll::-webkit-scrollbar {
    background-color: var(--textC2);
    /* background-color: beige; */
    width: 40px !important;
    height: 5px;

}

.products-row-scroll::-webkit-scrollbar-thumb {
    background-color: var(--bg4);
    border-radius: 30%;
}

.sec2Box1 {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: end;
    gap: 0.5rem;
}

.clock {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
}

.clock>svg {
    font-size: 4.8vmax;
    font-weight: 800;
    /* background-color: var(--textC3); */
    color: var(--textC3);
    /* color: rgb(33, 33, 33) !important; */
}

.clock>div>h2 {
    font: 600 1.4vmax 'Roboto';
    color: var(--textDark);
}

.clock>div>p {
    font: 600 0.8vmax 'Roboto';
    color: var(--textDark);
    line-height: 14px;
    letter-spacing: 5px;
}

.sec2Box2 {
    width: 50%;
}

@media screen and (max-width:600px) {
    .productPageContainer {
        width: 100%;
        /* background-color: #1a1b1b; */
    }
    .rightBox {
        width: 100%;
    }
    .leftBox {
        width: 20%;
        display: none ;
    }
    

    .colorSwitch {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        /* background-color: var(--bg1); */
        padding: 0.2vmax 1vmax;
    }

    .colorSwitch>span {
        width: 25px;
        height: 25px;
        display: inline-block;
        margin: 0.6vmax 0.3vmax;
        border-radius: 50%;
        border: 2px solid gray;
        cursor: pointer;
    }

    .productBox {
        width: 100%;
        padding: 2vmax 2vmax;
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* align-items: center; */
        gap: 1rem;
    }

    .imgbox {
        width: 100%;
    }

    .productDetailBox {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    .productDetailBox>span {
        padding: 2.5vmax 1.5vmax;
    }

    .lineSty {
        width: 24%;
        height: 3px;
        background-color: var(--textC1);
    }

    .h2Sty {
        font: 600 2.8vmax 'Roboto';
    }

    .h3Sty {
        font: 600 3vmax 'Roboto';
        color: var(--bg1);
    }

    .h4Sty {
        font: 600 2.5vmax 'Roboto';
        color: var(--textC1);
    }


    /* .productDetailBox > div > p{
    font: 300 1.8vmax 'Roboto';
    line-height: 23px;
    color: var(--textC1);
    text-align: justify;
} */

    .productDetailBox>p>pre,
    .productDetailBox>p {
        font: 300 2.2vmax 'Roboto';
        line-height: 23px;
        color: var(--textDark);
    }

    .productDetailBox>p>span {

        color: var(--bg1);
        font-weight: 600;
    }

    .actionBox {
        display: flex;
        gap: 1rem;
        padding: 2vmax 0;
        border-top: 1px solid var(--textC3);
    }

    .buybtn {
        padding: 1vmax 6vmax;
        font: 400 2.3vmax 'Roboto';
        color: var(--textC2);
        background-color: var(--bg1);
        border: none;
        cursor: pointer;
        transition: all 0.5s;
    }

    .thumb {
        width: 35px !important;
        /* height: 40px !important; */
        border: 2px solid var(--bg1) !important;
        /* padding: 1px !important; */
        border-radius: 0.1rem;
    }

    .sec2Box1 {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
    }

    .clock {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        justify-content: center;
    }

    .clock>svg {
        font-size: 8vmax;
        font-weight: 800;
        /* background-color: var(--textC3); */
        color: var(--textC3);
        /* color: rgb(33, 33, 33) !important; */
    }

    .clock>div>h2 {
        font: 600 4vmax 'Roboto';
        color: var(--textDark);
    }

    .clock>div>p {
        font: 600 2vmax 'Roboto';
        color: var(--textDark);
        line-height: 14px;
        letter-spacing: 5px;
    }

    .sec2Box2 {
        width: 100%;
    }
}