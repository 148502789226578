
.modal-overlay {
    position: absolute;
        z-index: 999;
        top: 5vh;
        left: 15%;
    background: rgb(255, 255, 255) !important;
    padding: 20px;
    /* border-radius: px; */
    width: 100%;
    max-width: 70vw;
    text-align: center;
   
    box-shadow: 1px 1px 10px rgb(61, 61, 61);
}
.modal-overlay-whatsapp{
    position: absolute;
        z-index: 999;
        top: 20vh;
        left: 25%;
        background: rgb(255, 255, 255) !important;
        padding: 20px;
        /* border-radius: 10px; */
        width: 100%;
        max-width: 50vw;
        /* height: 40vw; */
        text-align: center;
    
        box-shadow: 1px 1px 10px rgb(55, 55, 55);
}
.modalFormBtn{
    border: none;
    cursor: pointer;
        background-color: var(--bg2) !important;
        padding: 0.2vmax 0.8vmax;
        border-radius: 4rem;
        color: white;
        margin-bottom: 5px;

        > svg{
            color: black !important;
        }
}
.modalFormBtn:hover{
    box-shadow: 0 0 5px rgb(255, 255, 255);
}
.close-btn{
    text-align: right;
    padding-right: 1vmax;
    padding-bottom: 0.2vmax;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;

    cursor: pointer;
    > button{
        border: none;
        background-color: transparent;
        > svg{
            font-size: 20px;
        }
    }
}
.modal-video {
    width: 100%;
    max-height: 70vh;
    border-radius: 10px;
}

.modal-footer{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 2vmax 10%;
    padding-bottom: 3vmax;

   
}
.responsive-input {
    width: 100%;
background-color: rgb(255, 255, 255);
    padding: 0.1vmax 1vmax;
    border-radius: 2rem;
    box-shadow: 0 0 10px rgb(120, 255, 120);
    display: flex;
    align-items: center;

    border: none;
    outline: none;
    
    >svg {
           
            color: green;
            font-size: large;
        }

        >input{
            width: 95%;
            border: none;
            background-color: transparent;
                outline: none;
                    font-size: 16px;
                    padding: 10px 10px;
        }
}

.send-btn {
    height: 40px;
    border: none;
    cursor: pointer;
    background-color: darkgreen;
    padding: 0.1vmax 2vmax;
    border-radius: 2rem;
    color: white;
            box-shadow: 0 0 10px rgb(101, 209, 101);
}

.send-btn:hover {
    background-color: green;
    box-shadow: 0px 0  10px rgb(113, 113, 113);
}

/* ------------------------ whatsapp form -------------------- */
.bgWhite{
    background-color: white !important;
}
.sendHeading{
    font: 600 1.6vmax 'Roboto';
}
.whatsappFormBtnBox{
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 9999;
    position: fixed;
}
.whatsappFormBtn {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    cursor: pointer;
    background-color: green;
    /* padding: 0.2vmax; */
    border-radius: 2rem;
    color: white;
    font-size: medium;
    box-shadow: 0 0 10px green;
}

.whatsappFormBtn:hover {
    box-shadow: 0 0 5px rgb(255, 255, 255);
}

@media (max-width: 480px) {
    .sendHeading {
            font: 600 3vmax 'Roboto';
        }
    .modal-overlay {
        max-width: 95vw;
        padding: 5px;
        top: 30vh;
            left: 2.5%;
    }
.modal-overlay-whatsapp {
    position: absolute;
    z-index: 999;
    top: 30vh;
    left: 5%;
    background: rgb(255, 255, 255) !important;
    padding: 20px;
    /* border-radius: 10px; */
    width: 100%;
    max-width: 90vw;
    /* height: 40vw; */
    text-align: center;

    box-shadow: 1px 1px 10px rgb(133, 133, 133);
}
    .modal-video {
        max-height: 200px;
    }

    .responsive-input {
        font-size: 14px;
        padding: 6px 15px;
    }

        .modal-footer {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 1rem;
            padding: 2vmax 1%;
            padding-bottom: 3vmax;
    
    
        }
                .send-btn {
                    height: 35px;
                    border: none;
                    cursor: pointer;
                    background-color: darkgreen;
                    padding: 0.1vmax 2vmax;
                    border-radius: 2rem;
                    color: white;
                    box-shadow: 0 0 10px rgb(101, 209, 101);
                }
}

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #2196F3;
}

input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}