.hideSearch {
    /* display: none !important; */
    transition: all 0.5s;
    margin-top: -8.8vmax;
    position: absolute;
    z-index: -1;
}

.showSearch {
    /* display: none !important; */
    transition: all 0.5s;

    margin-top: 0vmax;
    position: absolute;
    z-index: 99;
}

.HeaderContainerMain {
    /* border-top: 4px solid gray; */
    position: absolute;
    position: fixed;
}

.logoColor {
    color: red;
}

.logoColor2 {
    color: black;
}

.HeaderContainer {
    height: 68px;
    width: 100vw;
    padding: 0.5rem 2vmax;
    background: linear-gradient(to right, var(--textC2), var(--textC1)) !important;
    display: flex;
    justify-content: space-between;
    /* border-bottom: 1px solid rgb(238, 238, 238); */
    /* position: absolute; */
}
.logoBox{
    display: flex;
    gap: 0.3rem;
}

.BroBtn{
    height: 60% !important;
    display: flex;
    align-items: center;
    gap: 0.4rem;
    padding: 0 1vmax;
    /* border: 1px solid gray; */
    border-radius: 4rem;
    background-color: white;
    color: var(--textC1) !important;
    border: none;
    box-shadow: 1px 1px 5px rgb(170, 170, 170);
}
.BroBtn:hover{
    background-color: var(--textC1);
    color: white !important;
}

.logoBox > a >img {
    /* width: 100%; */
    height: 100%;
    /* margin: 0.5vmax 1vmax; */
    /* mix-blend-mode:lighten;     */
    /* background-color: rgb(255, 255, 255);
    padding: 0.1vmax;
    border-radius: 0.4rem; */
}

.logoBox>h3 {
    display: flex;
    align-items: center;
    height: 100%;
    font: 600 1.1vmax 'Roboto';
    color: var(--textC1);
}

.linkBox {
    /* width: 50%; */
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.active {
    border-bottom: 1px solid var(--bg1) !important;
    /* color: var(--hoverText) !important; */
}

.linkBox>a {
    display: flex;
    align-items: center;
    height: 100%;
    text-decoration: none;
    font-family: 'Roboto';
    color: rgb(255, 255, 255);
    padding: 0.5rem 1rem;
    font-size: 15px;
    transition: 0.5s ease-in-out;
    font-weight: 500;
    text-align: center;
    /* background-color: aquamarine; */
}

.linkBox>a:hover {
    /* border-bottom:  1px solid var(--bg1) ;  */
    color: black;
}

.iconBox {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    /* background-color: rgba(59, 132, 59, 0); */
    padding: 1vmax;
}

.iconBoxx,
.iconBox>a {
    text-decoration: none;
}

.iconBox>a>svg {
    color: var(--textC2);
    font-size: 1.5vmax;
    transition: all 0.5s;
}

.iconBoxx>a>svg {
    color: var(--textC1);
    font-size: 3.5vmax;
    transition: all 0.5s;
}

.iconBoxx>a {
    margin: 0 1rem;
}

.iconBox>a>svg:hover {
    color: black;
    /* font-size: 1.5vmax; */
}

.menu {
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;
}

.menu>svg {
    font-size: 4vmax;
    color: var(--textC2);
}

.menuContainer {
    width: 100vw;
    max-width: 100%;
    height: 100vh;
    top: 0;
    position: absolute;
    z-index: 99999 !important;
    background-color: var(--textC1);
    padding: 1rem;
    flex-direction: column;
    display: flex;
    justify-content: space-around;
    align-items: center;


}

.menuContainer>div:first-child {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;
}

.menuContainer>div>a {
    font-size: 2vmax;
    color: var(--textLight);
    font-weight: 600;
    padding: 0.5rem;
    text-decoration: none;

}

.menuContainer>div>a:hover {
    color: black;
}

.menulogoBox {
    width: 100%;
    padding: 1.2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* border-bottom: 1px solid gray; */

}

.menulogoBox>p {
    font-size: 0.7rem;
    text-align: center;
    color: var(--textC3);
    font-weight: 600
}

.menulinkBox {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin: 3rem 0;

}

.menulinkBox>div {}

.menulinkBox>div>a {
    width: 100;
    text-decoration: none;
    font-family: Sansita;
    color: var(--textC1);
    /* border: 2px solid black; */
    padding: 0.5rem 0.2rem;
    font-size: 15px;
    transition: 0.5s ease-in-out;
    font-weight: 600;
    text-align: center;
    margin: 10px;
}

.menuiconBox {
    padding: 0.2rem;
    display: flex;
    gap: 1rem;
    border-bottom: 1px solid gray;
    border-top: 1px solid gray;
    padding: 1rem 0;
}

.menulinkBox>div>a:hover {
    color: var(--hoverText);
    padding-left: 0.5rem;
}

.hide {
    transition: all 0.4s ease-in-out;
    top: -100%;
}

.show {
    transition: all 0.4s ease-in-out;
    top: 9vh;
}

.brandName {
    width: 100%;
    text-align: center;
    padding: 0.2rem 0;
    text-align: center;
    color: var(--textC1);
    font-size: 0.8rem;
}

/* search style  */
.searchBox {
    width: 100%;
    padding: 0.8vmax;
    display: flex;
    justify-content: center;
    background-color: var(--bg2);
}

.searchBox>div {
    width: 60%;
    border: 1px solid var(--textC3);
}

.searchBox>div>input:first-child {
    width: 80%;
    border: none;
    outline: none;
    padding: 1vmax;
    padding-left: 1.5vmax;
    font: 300 1vmax 'Roboto';
}

.searchBox>div>input:last-child {
    width: 20%;
    border: none;
    outline: none;
    padding: 1vmax;
    padding-left: 1.5vmax;
    font: 300 1vmax 'Roboto';
    background-color: var(--bg1);
    cursor: pointer;
    color: var(--textC2);
}

/* search style end */
@media screen and (max-width:952px) {
    .hideSearch {
        /* display: none !important; */
        transition: all 0.5s;
        margin-top: 0vmax;
        position: relative;
        z-index: -1;
    }

    /* .showSearch{
        display: none !important;
        transition: all 0.5s;
    
        margin-top: 0vmax;
        position: relative;
        z-index: -1;
    } */
    .HeaderContainer {
        width: 100vw;
        height: 65px;
        padding: 0.1vmax 0.5vmax;

    }

    .logoBox>h3 {
        font: 300 4vmax 'Roboto';
        color: white;
    }

    .logoBox > a >img {
        /* width: 150px; */
        height: 100%;
        /* mix-blend-mode: darken !important; */
        padding: 0.4rem;
    }

    .menu {
        display: block;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .linkBox,
    .iconBox {
        display: none;
    }

    .brandName {
        width: 100%;
        text-align: center;
        color: var(--textC1);
        font-size: 0.6rem;
    }

    /* search style  */
    .searchBox {
        width: 100%;
        padding: 0.8vmax;
        display: flex;
        justify-content: center;
    }

    .searchBox>div {
        width: 100%;
        border: 1px solid var(--textC3);
    }

    .searchBox>div>input:first-child {
        width: 80%;
        border: none;
        outline: none;
        padding: 1.3vmax;
        padding-left: 1.5vmax;
        font: 300 2vmax 'Roboto';
    }

    .searchBox>div>input:last-child {
        width: 20%;
        border: none;
        outline: none;
        padding: 1.3vmax;
        padding-left: 1.5vmax;
        font: 300 2vmax 'Roboto';
        background-color: var(--bg1);
        cursor: pointer;
        color: var(--textC2);
    }
    .BroBtn{
        height: 75% !important;
        display: flex;
        gap: 0.4rem;
        /* border: 1px solid gray; */
        border-radius: 4rem;
        background-color: white;
        color: var(--textC1) !important;
        box-shadow: 1px 1px 5px rgb(170, 170, 170);
        padding: 0.6rem 1.5rem !important;
    }
    /* search style end */
}

@media screen and (min-width:952px) {
    .menuContainer {
        padding: 1vmax;
        display: none;
    }
}