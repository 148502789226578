*{
    margin: 0;
    padding: 0;
    max-width: 100%;
    box-sizing: border-box;
    /* padding-top: 68px; */
    scroll-behavior: smooth;

}
.downMargin{
    padding-top: 64px;
}
:root{

    /* --bg1: #009ddd;
    --bg3: #006994; */

    --bg1: #cb95c3;
    --bg3: #e1c2dd;

    



    --textC1:#6266ae;
    --textC2: rgb(255, 255, 255);
    --textDark: black;
    --textLight: rgb(255, 255, 255);

}

@font-face {
    font-family: Roboto;
    src: url(../src/utils/Poppins//Poppins-Regular.ttf);
}
@font-face {
    font-family: roboto;
    src: url(../src/utils/Poppins//Poppins-Regular.ttf);

}